.l-footer {
  border-top: 1px solid var(--colorBorder1);
  margin: 60rem var(--sidePaddingSp2) 0;
  padding: 35rem 0;
  @include wide {
    max-width: 1435rem;
    width: calc(100% - var(--sidePaddingPc1) * 2);
    margin: 108rem auto 0;
    padding: 42rem 0;
  }
}
.l-footer__content {
  @include wide {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
  }
}
.l-footer__copyright {
  letter-spacing: 0.04em;
  @include sp {
    text-align: center;
    font-size: 10rem;
  }
  @include wide {
    font-size: 12rem;
  }
}
.l-footer__link {
  font-weight: 500;
  letter-spacing: 0;
  font-size: 12rem;
  @include sp {
    text-align: center;
    margin-bottom: 25rem;
  }
  @include wide {
    a {
      color:inherit;
      display: inline-block;
      padding-bottom: 1px;
      background: linear-gradient(var(--colorText1), var(--colorText1)) 100% 100% / 0 1px no-repeat;
      transition:color .6s, background-size .6s cubic-bezier(0.215, 0.61, 0.355, 1);
      @at-root .bgcolor-white & {
        background: linear-gradient(var(--colorText2), var(--colorText2)) 100% 100% / 0 1px no-repeat;
      }
      &:hover {
        background-position: 0 100%;
        background-size: 100% 1px;        
      }
    }
  }
}