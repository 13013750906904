.top-wrapper {
  &:not(.is-scroll-allow) {
    height: var(--vh);
    overflow-y: hidden;
  }
}


.top-kv {
  height: var(--vh);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--colorBg2);
}
.top-kv__inner {
  width: 100%;
  height: 3rem;
  overflow: hidden;
  position: relative;
  transform: scale(0);
  margin:0 auto;
  animation: topLoading 2.5s forwards;
  background: var(--colorBg1);
}
@keyframes topLoading {
  0% {
    transform: scaleX(0);
    height: 3rem;
  }
  60% {
    transform: scaleX(1);
    height: 3rem;
  }
  100% {
    transform: scaleX(1);
    height: 100%;
  }
}
.top-kv__content {
  text-align: center;
  width: 100%;
  height: var(--vh);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 1.8s .1s, transform .8s .12s;
  @include wide {
    justify-content: space-between;
    padding-top: 30rem;
    @media (max-height: 700px) {
      padding-top: 0;
    }
  }
  .top-kv__inner.is-active & {
    opacity: 1;
    transform: translateY(0);
  }
}
.top-kv__object {
  background:url("/assets/img/top/kv_bg.png") no-repeat center bottom / contain;
  transition: opacity 1.2s, transform 1.2s;
  opacity: 0;
  @include sp {
    position: absolute;
    width: 175%;
    height: 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,60rem);
    &.is-active {
      transform: translate(-50%,0);
    }
  }
  @include wide {
    width: pcLiquidSize(916);
    padding-top: 22%;
    margin-right: auto;
    margin-left: auto;
    transform: translateY(60rem);
    &.is-active {
      transform: translateY(0);
    }
    @media (max-height: 700px) {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%,60rem);
      &.is-active {
        transform: translate(-50%,0);
      }
    }
  }
  &.is-active {
    opacity: 1;
  }
}
.top-kv__text-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include sp {
    height: calc(100% - 90rem);
  }
  @include wide {
    max-height: 670rem;
    flex:1;
    @media (max-height: 700px) {
      padding-bottom: 150rem;
    }
  }
}
.top-kv__heading__logo {
  display: block;
  margin-right: auto;
  margin-left: auto;
  @include sp {
    width: spLiquidSize(170);
  }
  @include wide {
    width: pcLiquidSize(178);
  }
  img {
    width: 100%;
  }
}
.top-kv__heading__text {
  display: block;
  font-size: 35rem;
  font-weight: 500;
  letter-spacing: 0.245em;
  @include sp {
    font-size: spLiquidSize(35);
    margin-top: spLiquidSize(70);
  }
  @include wide {
    font-size: pcLiquidSize(67);
    margin-top:pcLiquidSize(92);;
  }
}


.top-intro {
  text-align: center;
  overflow: hidden;
  margin-top: 100rem;
  margin-bottom: 95rem;
  @include wide {
    margin-top: pcLiquidSize(180);
    margin-bottom: pcLiquidSize(110);
  }
}
.top-intro__lead {
  margin-top: 15rem;
  @include wide {
    margin-top: pcLiquidSize(45);
  }
}
.top-intro__button {
  margin-top: 60rem;
  @include wide {
    margin-top: pcLiquidSize(140);
  }
  .m-primary-button {
    margin-right: auto;
    margin-left: auto;
  }
}

.top-feature {
  overflow: hidden;
  padding-bottom: 155rem;
  @include wide {
    padding:0 var(--sidePaddingPc1) pcLiquidSize(180);
  }
}
.top-feature__block {
  @include sp {
    &:not(:last-child) {
      margin-bottom: 75rem;
    }
  }
}
.top-feature__block__inner {
  @include wide {
    display: flex;
    max-width: 1386rem;
    margin-right: auto;
    margin-left: auto;
    .top-feature__block--reverse & {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
}
.top-feature__textarea {
  position: relative;
  @include sp {
    padding:0 var(--sidePaddingSp1) 0;
  }
  @include wide {
    max-width: 573rem;
    padding: pcLiquidSize(70) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.top-feature__textarea__inner {
  @include wide {
    top: pcLiquidSize(90);
  }
}
.top-feature__heading {
  overflow: hidden;
}
.top-feature__heading__text {
  font-size: 30rem;
  font-weight: 600;
  letter-spacing: 0.2em;
  margin-bottom: 38rem;
  @include wide {
    font-size: pcLiquidSize(45);
    margin-bottom: pcLiquidSize(70);
  }
}
.top-feature__lead {
  text-align: justify;
  line-height: 2;
  @include sp {
    letter-spacing: 0.1em;
  }
  @include wide {
    font-size: pcLiquidSize(22);
  }
  .top-feature__heading+ & {
    margin-top: 0;
  }
  &+& {
    margin-top: 20rem;
    @include wide {
      margin-top: pcLiquidSize(35);
    }
  }
}
.top-feature__notes {
  font-size: 10rem;
  letter-spacing: 0.2em;
  @include wide {
    font-size: 12rem;
  }
}
.top-feature__image {
  display: block;
  @include sp {
    margin-right: -5rem;
    margin-left: auto;
    margin-bottom: 45rem;
    width: 295rem;
  }
  @include wide {
    flex:none;
    width: 42.6%;
    margin-right: 10%;
  }
  .top-feature__block--reverse & {
    @include sp {
      width: 329rem;
      margin-left: -5rem;
      margin-right: auto;
    }
    @include wide {
      margin-right: 0;
      width: 47.5%;
      margin-left: 10%;
    }
  }
} 
.top-feature__image__inner {
  position: relative;
  width: 100%;
  padding-top: 217%;
}
.top-feature__copyright {
  position: absolute;
  width:100%;
  height: calc(100% + 30rem);
  top: 0;
  left: 0;
}
.top-feature__copyright__text {
  font-family: var(--fontEn1);
  letter-spacing: 0;
  //font-size: pcLiquidSize(10);
  fill:var(--colorText3);
  font-size: 1.5em;
  @include wide {
    //font-size: pcLiquidSize(12);
    font-size: 0.7em;
  }
}
.top-feature__image__frame {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  .top-feature__block--1 & {
    clip-path: url(#top-feature__shape1);
  }
  .top-feature__block--2 & {
    clip-path: url(#top-feature__shape2);
  }
  .top-feature__block--3 & {
    clip-path: url(#top-feature__shape3);
  }
}
.top-feature__image__frame__content {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: var(--vh);
  background: var(--colorMain1);
  img {
    object-fit: cover;
    object-position: center bottom;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .top-feature__block--3 & {
    @include wide {
      left:auto;
      right: 7%;
    }
    img {
      object-position: right bottom;
    }
  }
}
.top-feature__shape__path {
  transform:scale(0.00169,0.00078);
}


.top-concept {
  text-align: center;
}
.top-concept__text-area {
  overflow: hidden;
}
.top-concept__heading {
  font-size: 30rem;
  font-weight: 600;
  letter-spacing: 0.245em;
  margin-bottom: 30rem;
  @include wide {
    font-size: pcLiquidSize(55);
    margin-bottom: 60rem;
  }
}
.top-concept__lead {
  line-height: 2;
  @include sp {
    letter-spacing: 0.1em;
  }
  @include wide {
    font-size: pcLiquidSize(24);
  }
  &+& {
    margin-top: 20rem;
    @include wide {
      margin-top: pcLiquidSize(35);
    }
  }
}
.top-concept__logo {
  margin:110rem auto 75rem;
  width: 140rem;
  position: relative;
  @include wide {
    width: pcLiquidSize(206);
    margin-top: pcLiquidSize(180);
    margin-bottom: pcLiquidSize(140);
  }
}


.top-contact {
  margin-top: 120rem;
  @include wide {
    margin-top: pcLiquidSize(209);
  }
}
.top-contact__button {
  margin-right: auto;
  margin-left: auto;
}


.top-movie-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}
.top-movie-modal__content {
  margin-right: auto;
  margin-left: auto;
  width: calc(100% - var(--sidePaddingSp2) * 2);
  position: relative;
  @include wide {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
}
.top-movie-modal__close {
  @include sp {
    position: absolute;
    right: 0;
    top: -55rem;
  }
  @include wide {
    margin: 30rem auto 0;
  }
}