html {
  font-family:var(--fontBase);
  width: 100%;
  height: 100%;
  font-size: calc( 100vw / var(--spViewport));
  @include wide {
    font-size: 1px;
  }
}
body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-size: 14rem;
  font-weight: 500;
  line-height: 1.447;
  letter-spacing: .25em;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  color: var(--colorText1);
  background: var(--colorBg1);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  font-feature-settings: "palt" 1;
  position: relative;
  @include wide {
    font-size: 18rem;
    line-height: 1.447;
  }
  &.bgcolor-white {
    background: var(--colorBg2);
    color: var(--colorText2);
  }
}
a {
  outline: none;
  color: var(--colorText1);
  text-decoration: none;
  &[data-anchor] {
    cursor: pointer;
  }
}
a:focus,
*:focus {
  outline:none;
}
h1,h2,h3,h4,h5,h6,strong,th {
  font-weight: normal;
}
em {
  font-style: normal;
}
select,input,textarea,button {
  font-family: inherit;
  border-radius: 0;
  -webkit-appearance: none; 
  display: block;
}
select,button {
 cursor: pointer;
}
input[type=number] {
  -moz-appearance:textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
input[type=search]::-webkit-search-cancel-button {
   -webkit-appearance: none;
}
input,textarea,select { 
  &::-webkit-input-placeholder {
    font-size: 15rem;
    font-weight: 400;
    color: #818181;
    opacity:1;
    letter-spacing: 0.06em;
    @include wide {
      font-size: 16rem;
    }
  }
  &:-moz-placeholder {
    font-size: 15rem;
    font-weight: 400;
    color: #818181;
    opacity:1;
    letter-spacing: 0.06em;
    @include wide {
      font-size: 16rem;
    }
  }
  &::-moz-placeholder {
    font-size: 15rem;
    font-weight: 400;
    color: #818181;
    opacity:1;
    letter-spacing: 0.06em;
    @include wide {
      font-size: 16rem;
    }
  }
  &:-ms-input-placeholder {
    font-size: 15rem;
    font-weight: 400;
    color: #818181;
    opacity:1;
    letter-spacing: 0.06em;
    @include wide {
      font-size: 16rem;
    }
  }  
}
main {
  margin-bottom: auto;
  //height: 100%;
}
ul {
  list-style-type: none;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

