.primary-heading {
  font-size: 30rem;
  font-weight: 600;
  letter-spacing: 0.245em;
  text-align: center;
  @include wide {
    font-size: pcLiquidSize(50);
  }
}
.primary-lead {
  line-height: 2;
  @include sp {
    font-size: 14rem;
    letter-spacing: 0.13em;
  }
  @include wide {
    font-size: pcLiquidSize(24);
  }
}