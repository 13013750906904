:root {

  // Base
  --designSp: #{$designSp};
  --designPc: #{$designPc};
  --spViewport: 375;

  // Break point 
  --breakSpMin: #{$breakSpMin};
  --breakSp: #{$breakSp};
  --breakPc: #{$breakPc};

  // Width & Height  
  --widthDefault: 1180rem;
  --widthMax: 1393rem;
  --widthMin: 1000rem;

  // Base padding  
  --sidePaddingSp1:40rem;
  --sidePaddingSp2:20rem;
  --sidePaddingPc1:clamp(0rem,8vw,80rem);  

  // Font
  --fontBase: YakuHanJP_Noto,'Zen Kaku Gothic New','Yu Gothic Medium','游ゴシック Medium', YuGothic, '游ゴシック体', 'メイリオ', sans-serif;
  --fontEn1: 'Inter', sans-serif;

  // Color
  --colorMain1:#5A2B5C;
  --colorMain2:#7B557D;
  --colorBg1: #545A60;
  --colorBg2: #FFFFFF;
  --colorText1: #FFFFFF;
  --colorText2: #4E4E4E;
  --colorText3: #7A7A7A;
  --colorBorder1: #7A7A7A;

 }