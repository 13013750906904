
// ひともじずつ表示

.js-text-fadein {
  opacity: 0;
  &.is-active  {
    animation:textFadeinWrap 2.5s cubic-bezier(.215,.61,.355,1) forwards;
  }
}
.js-text-fadein__text {
  opacity: 0;
  display: inline-block;
  .js-text-fadein.is-active & {
    animation:textFadeinContent 2.5s cubic-bezier(.215,.61,.355,1) forwards;
  }
}
@keyframes textFadeinWrap {
  0% {
    opacity:0;
    transform: translateX(50px);
  }
  100% {
    opacity:1;
    transform: translateX(0);
  }
}
@keyframes textFadeinContent {
  0% {
    opacity:0;
    transform: translateX(25px);
  }
  100% {
    opacity:1;
    transform: translateX(0);
  }
}


// フェード

.m-scroll-fadein {
  opacity: 0;
  &.is-active {
    transition: opacity 4s;
    opacity: 1;
  }
}
.m-scroll-fadeup {
  opacity: 0;
  transform: translateY(50px);
  &.is-active {
    transition: opacity 4s, transform 4s;
    transform: translateY(0);
    opacity: 1;
  }
}
.m-scroll-fadeleft {
  opacity: 0;
  transform: translateX(-60px);
  &.is-active {
    transition: opacity 4s, transform 4s;
    transform: translateX(0);
    opacity: 1;
  }
}
.m-scroll-faderight {
  opacity: 0;
  transform: translateX(60px);
  &.is-active {
    transition: opacity 4s, transform 4s;
    transform: translateX(0);
    opacity: 1;
  }
}

// ロゴアニメーション

.m-logo-animation__parts  {
  opacity: 0;
  transition: opacity 1.8s, transform 1.8s;
  .m-logo-animation.is-active & {
    opacity: 1;
    transform: translate(0,0) ;
  }
  &--left {
    transform: translate(-50rem,50rem);
  }
  &--right {
    transform: translate(50rem,50rem);
    transition-delay: .3s;
  }
  &--bottom {
    transform: translate(0rem,50rem);
    transition-delay: .5s;
  }
  &--text {
    transform: translate(0rem,30rem);
    transition-delay: .7s;
  }
}
.m-logo-animation__content {
  width: 100%;
  height: 100%;
  font-size: 0;
  overflow: visible;
}