@charset "UTF-8";
:root {
  --designSp: 750;
  --designPc: 1736;
  --spViewport: 375;
  --breakSpMin: 374px;
  --breakSp: 767px;
  --breakPc: 768px;
  --widthDefault: 1180rem;
  --widthMax: 1393rem;
  --widthMin: 1000rem;
  --sidePaddingSp1:40rem;
  --sidePaddingSp2:20rem;
  --sidePaddingPc1:clamp(0rem,8vw,80rem);
  --fontBase: YakuHanJP_Noto,"Zen Kaku Gothic New","Yu Gothic Medium","游ゴシック Medium", YuGothic, "游ゴシック体", "メイリオ", sans-serif;
  --fontEn1: "Inter", sans-serif;
  --colorMain1:#5A2B5C;
  --colorMain2:#7B557D;
  --colorBg1: #545A60;
  --colorBg2: #FFFFFF;
  --colorText1: #FFFFFF;
  --colorText2: #4E4E4E;
  --colorText3: #7A7A7A;
  --colorBorder1: #7A7A7A;
}

/* resset.dev • v5.0.2 */
/* # =================================================================
   # Global selectors
   # ================================================================= */
html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  /* Prevent adjustments of font size after orientation changes in iOS */
  word-break: normal;
  -moz-tab-size: 4;
  tab-size: 4;
}

*,
::before,
::after {
  background-repeat: no-repeat;
  /* Set `background-repeat: no-repeat` to all elements and pseudo elements */
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

* {
  padding: 0;
  /* Reset `padding` and `margin` of all elements */
  margin: 0;
}

/* # =================================================================
   # General elements
   # ================================================================= */
hr {
  overflow: visible;
  /* Show the overflow in Edge and IE */
  height: 0;
  /* Add the correct box sizing in Firefox */
  color: inherit;
  /* Correct border color in Firefox. */
}

details,
main {
  display: block;
  /* Render the `main` element consistently in IE. */
}

summary {
  display: list-item;
  /* Add the correct display in all browsers */
}

small {
  font-size: 80%;
  /* Set font-size to 80% in `small` elements */
}

[hidden] {
  display: none;
  /* Add the correct display in IE */
}

abbr[title] {
  border-bottom: none;
  /* Remove the bottom border in Chrome 57 */
  /* Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari */
  text-decoration: underline;
  text-decoration: underline dotted;
}

a {
  background-color: transparent;
  /* Remove the gray background on active links in IE 10 */
}

a:active,
a:hover {
  outline-width: 0;
  /* Remove the outline when hovering in all browsers */
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* Specify the font family of code elements */
}

pre {
  font-size: 1em;
  /* Correct the odd `em` font sizing in all browsers */
}

b,
strong {
  font-weight: bolder;
  /* Add the correct font weight in Chrome, Edge, and Safari */
}

/* https://gist.github.com/unruthless/413930 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  border-color: inherit;
  /* Correct border color in all Chrome, Edge, and Safari. */
  text-indent: 0;
  /* Remove text indentation in Chrome, Edge, and Safari */
}

iframe {
  border-style: none;
}

/* # =================================================================
   # Forms
   # ================================================================= */
input {
  border-radius: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
  /* Correct the cursor style of increment and decrement buttons in Chrome */
}

[type=search] {
  -webkit-appearance: textfield;
  /* Correct the odd appearance in Chrome and Safari */
  outline-offset: -2px;
  /* Correct the outline style in Safari */
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
  /* Remove the inner padding in Chrome and Safari on macOS */
}

textarea {
  overflow: auto;
  /* Internet Explorer 11+ */
  resize: vertical;
  /* Specify textarea resizability */
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* Specify font inheritance of form elements */
}

optgroup {
  font-weight: bold;
  /* Restore the font weight unset by the previous rule */
}

button {
  overflow: visible;
  /* Address `overflow` set to `hidden` in IE 8/9/10/11 */
}

button,
select {
  text-transform: none;
  /* Firefox 40+, Internet Explorer 11- */
}

/* Apply cursor pointer to button elements */
button,
[type=button],
[type=reset],
[type=submit],
[role=button] {
  cursor: pointer;
}

/* Remove inner padding and border in Firefox 4+ */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/* Replace focus style removed in the border reset above */
button:-moz-focusring,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  /* Correct the inability to style clickable types in iOS */
}

/* Remove the default button styling in all browsers */
button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
}

a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline-width: 0;
}

/* Style select like a standard input */
select {
  -moz-appearance: none;
  /* Firefox 36+ */
  -webkit-appearance: none;
  /* Chrome 41+ */
}

select::-ms-expand {
  display: none;
  /* Internet Explorer 11+ */
}

select::-ms-value {
  color: currentColor;
  /* Internet Explorer 11+ */
}

legend {
  border: 0;
  /* Correct `color` not being inherited in IE 8/9/10/11 */
  color: inherit;
  /* Correct the color inheritance from `fieldset` elements in IE */
  display: table;
  /* Correct the text wrapping in Edge and IE */
  max-width: 100%;
  /* Correct the text wrapping in Edge and IE */
  white-space: normal;
  /* Correct the text wrapping in Edge and IE */
  max-width: 100%;
  /* Correct the text wrapping in Edge 18- and IE */
}

::-webkit-file-upload-button {
  /* Correct the inability to style clickable types in iOS and Safari */
  -webkit-appearance: button;
  color: inherit;
  font: inherit;
  /* Change font properties to `inherit` in Chrome and Safari */
}

/* Replace pointer cursor in disabled elements */
[disabled] {
  cursor: default;
}

/* # =================================================================
   # Specify media element style
   # ================================================================= */
img {
  border-style: none;
  /* Remove border when inside `a` element in IE 8/9/10 */
}

/* Add the correct vertical alignment in Chrome, Firefox, and Opera */
progress {
  vertical-align: baseline;
}

/* # =================================================================
   # Accessibility
   # ================================================================= */
/* Specify the progress cursor of updating elements */
[aria-busy=true] {
  cursor: progress;
}

/* Specify the pointer cursor of trigger elements */
[aria-controls] {
  cursor: pointer;
}

/* Specify the unstyled cursor of disabled, not-editable, or otherwise inoperable elements */
[aria-disabled=true] {
  cursor: default;
}

html {
  font-family: var(--fontBase);
  width: 100%;
  height: 100%;
  font-size: calc(100vw / var(--spViewport));
}
@media (min-width: 768px) {
  html {
    font-size: 1px;
  }
}

body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-size: 14rem;
  font-weight: 500;
  line-height: 1.447;
  letter-spacing: 0.25em;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  color: var(--colorText1);
  background: var(--colorBg1);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  font-feature-settings: "palt" 1;
  position: relative;
}
@media (min-width: 768px) {
  body {
    font-size: 18rem;
    line-height: 1.447;
  }
}
body.bgcolor-white {
  background: var(--colorBg2);
  color: var(--colorText2);
}

a {
  outline: none;
  color: var(--colorText1);
  text-decoration: none;
}
a[data-anchor] {
  cursor: pointer;
}

a:focus,
*:focus {
  outline: none;
}

h1, h2, h3, h4, h5, h6, strong, th {
  font-weight: normal;
}

em {
  font-style: normal;
}

select, input, textarea, button {
  font-family: inherit;
  border-radius: 0;
  -webkit-appearance: none;
  display: block;
}

select, button {
  cursor: pointer;
}

input[type=number] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder, select::-webkit-input-placeholder {
  font-size: 15rem;
  font-weight: 400;
  color: #818181;
  opacity: 1;
  letter-spacing: 0.06em;
}
@media (min-width: 768px) {
  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder, select::-webkit-input-placeholder {
    font-size: 16rem;
  }
}
input:-moz-placeholder, textarea:-moz-placeholder, select:-moz-placeholder {
  font-size: 15rem;
  font-weight: 400;
  color: #818181;
  opacity: 1;
  letter-spacing: 0.06em;
}
@media (min-width: 768px) {
  input:-moz-placeholder, textarea:-moz-placeholder, select:-moz-placeholder {
    font-size: 16rem;
  }
}
input::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder {
  font-size: 15rem;
  font-weight: 400;
  color: #818181;
  opacity: 1;
  letter-spacing: 0.06em;
}
@media (min-width: 768px) {
  input::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder {
    font-size: 16rem;
  }
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder {
  font-size: 15rem;
  font-weight: 400;
  color: #818181;
  opacity: 1;
  letter-spacing: 0.06em;
}
@media (min-width: 768px) {
  input:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder {
    font-size: 16rem;
  }
}

main {
  margin-bottom: auto;
}

ul {
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

@media (min-width: 768px) {
  .is-sp {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .is-wide {
    display: none !important;
  }
}

.js-text-fadein {
  opacity: 0;
}
.js-text-fadein.is-active {
  animation: textFadeinWrap 2.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

.js-text-fadein__text {
  opacity: 0;
  display: inline-block;
}
.js-text-fadein.is-active .js-text-fadein__text {
  animation: textFadeinContent 2.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

@keyframes textFadeinWrap {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes textFadeinContent {
  0% {
    opacity: 0;
    transform: translateX(25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.m-scroll-fadein {
  opacity: 0;
}
.m-scroll-fadein.is-active {
  transition: opacity 4s;
  opacity: 1;
}

.m-scroll-fadeup {
  opacity: 0;
  transform: translateY(50px);
}
.m-scroll-fadeup.is-active {
  transition: opacity 4s, transform 4s;
  transform: translateY(0);
  opacity: 1;
}

.m-scroll-fadeleft {
  opacity: 0;
  transform: translateX(-60px);
}
.m-scroll-fadeleft.is-active {
  transition: opacity 4s, transform 4s;
  transform: translateX(0);
  opacity: 1;
}

.m-scroll-faderight {
  opacity: 0;
  transform: translateX(60px);
}
.m-scroll-faderight.is-active {
  transition: opacity 4s, transform 4s;
  transform: translateX(0);
  opacity: 1;
}

.m-logo-animation__parts {
  opacity: 0;
  transition: opacity 1.8s, transform 1.8s;
}
.m-logo-animation.is-active .m-logo-animation__parts {
  opacity: 1;
  transform: translate(0, 0);
}
.m-logo-animation__parts--left {
  transform: translate(-50rem, 50rem);
}
.m-logo-animation__parts--right {
  transform: translate(50rem, 50rem);
  transition-delay: 0.3s;
}
.m-logo-animation__parts--bottom {
  transform: translate(0rem, 50rem);
  transition-delay: 0.5s;
}
.m-logo-animation__parts--text {
  transform: translate(0rem, 30rem);
  transition-delay: 0.7s;
}

.m-logo-animation__content {
  width: 100%;
  height: 100%;
  font-size: 0;
  overflow: visible;
}

.primary-heading {
  font-size: 30rem;
  font-weight: 600;
  letter-spacing: 0.245em;
  text-align: center;
}
@media (min-width: 768px) {
  .primary-heading {
    font-size: clamp(0rem,3.168202765vw,50rem);
  }
}

.primary-lead {
  line-height: 2;
}
@media (max-width: 767px) {
  .primary-lead {
    font-size: 14rem;
    letter-spacing: 0.13em;
  }
}
@media (min-width: 768px) {
  .primary-lead {
    font-size: clamp(0rem,1.5207373272vw,24rem);
  }
}

.m-primary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E5E5E5;
  color: var(--colorText2);
  font-size: 17rem;
  letter-spacing: 0.245em;
  max-width: 335rem;
  height: 62rem;
  border: 6rem solid #C7C7C7;
  padding: 0 20rem;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .m-primary-button {
    max-width: clamp(0rem,27.3732718894vw,432rem);
    height: clamp(0rem,4.9423963134vw,78rem);
    font-size: clamp(0rem,1.3940092166vw,22rem);
    border-width: clamp(0rem,0.5069124424vw,8rem);
    padding: 0 clamp(0rem,1.267281106vw,20rem);
    transition: 0.5s;
    cursor: pointer;
  }
  .m-primary-button:hover {
    background: var(--colorMain1);
    border-color: var(--colorMain2);
    color: var(--colorText1);
  }
}
@media (min-width: 768px) {
  .m-primary-button--pc-no-liquid {
    max-width: 642rem;
    height: 115rem;
    font-size: 30rem;
    border-width: 10rem;
    padding: 0 20rem;
  }
  .m-primary-button--pc-no-liquid:after {
    margin-left: 5rem;
    width: 35rem;
    height: 21rem;
  }
}
.m-primary-button--left {
  justify-content: space-between;
}
@media (min-width: 768px) {
  .m-primary-button--left {
    padding-left: clamp(0rem,3.168202765vw,50rem);
    padding-right: clamp(0rem,3.168202765vw,50rem);
  }
  .m-primary-button--left.m-primary-button--pc-no-liquid {
    padding-left: 50rem;
    padding-right: 50rem;
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  transition: 0.4s;
}
.modal:not(.is-active) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.modal__overlay {
  background: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.modal__content {
  position: relative;
  z-index: 1;
  pointer-events: none;
}

.modal__content__inner {
  pointer-events: auto;
}

.modal__movie {
  width: 100%;
  padding-top: 56.4%;
  position: relative;
}

.modal__movie__frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal__close {
  position: relative;
  width: 45rem;
  height: 45rem;
  cursor: pointer;
  pointer-events: auto;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
@media (min-width: 768px) {
  .modal__close {
    width: 42px;
    height: 42px;
  }
}
.modal__close::before, .modal__close::after {
  content: "";
  position: absolute;
  width: 56rem;
  height: 1px;
  top: 50%;
  left: 50%;
  background: #fff;
}
@media (min-width: 768px) {
  .modal__close::before, .modal__close::after {
    width: 48px;
  }
}
.modal__close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.modal__close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.l-footer {
  border-top: 1px solid var(--colorBorder1);
  margin: 60rem var(--sidePaddingSp2) 0;
  padding: 35rem 0;
}
@media (min-width: 768px) {
  .l-footer {
    max-width: 1435rem;
    width: calc(100% - var(--sidePaddingPc1) * 2);
    margin: 108rem auto 0;
    padding: 42rem 0;
  }
}

@media (min-width: 768px) {
  .l-footer__content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
  }
}

.l-footer__copyright {
  letter-spacing: 0.04em;
}
@media (max-width: 767px) {
  .l-footer__copyright {
    text-align: center;
    font-size: 10rem;
  }
}
@media (min-width: 768px) {
  .l-footer__copyright {
    font-size: 12rem;
  }
}

.l-footer__link {
  font-weight: 500;
  letter-spacing: 0;
  font-size: 12rem;
}
@media (max-width: 767px) {
  .l-footer__link {
    text-align: center;
    margin-bottom: 25rem;
  }
}
@media (min-width: 768px) {
  .l-footer__link a {
    color: inherit;
    display: inline-block;
    padding-bottom: 1px;
    background: linear-gradient(var(--colorText1), var(--colorText1)) 100% 100%/0 1px no-repeat;
    transition: color 0.6s, background-size 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .bgcolor-white .l-footer__link a {
    background: linear-gradient(var(--colorText2), var(--colorText2)) 100% 100%/0 1px no-repeat;
  }

  .l-footer__link a:hover {
    background-position: 0 100%;
    background-size: 100% 1px;
  }
}

.top-wrapper:not(.is-scroll-allow) {
  height: var(--vh);
  overflow-y: hidden;
}

.top-kv {
  height: var(--vh);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--colorBg2);
}

.top-kv__inner {
  width: 100%;
  height: 3rem;
  overflow: hidden;
  position: relative;
  transform: scale(0);
  margin: 0 auto;
  animation: topLoading 2.5s forwards;
  background: var(--colorBg1);
}

@keyframes topLoading {
  0% {
    transform: scaleX(0);
    height: 3rem;
  }
  60% {
    transform: scaleX(1);
    height: 3rem;
  }
  100% {
    transform: scaleX(1);
    height: 100%;
  }
}
.top-kv__content {
  text-align: center;
  width: 100%;
  height: var(--vh);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 1.8s 0.1s, transform 0.8s 0.12s;
}
@media (min-width: 768px) {
  .top-kv__content {
    justify-content: space-between;
    padding-top: 30rem;
  }
}
@media (min-width: 768px) and (max-height: 700px) {
  .top-kv__content {
    padding-top: 0;
  }
}
.top-kv__inner.is-active .top-kv__content {
  opacity: 1;
  transform: translateY(0);
}

.top-kv__object {
  background: url("/assets/img/top/kv_bg.png") no-repeat center bottom/contain;
  transition: opacity 1.2s, transform 1.2s;
  opacity: 0;
}
@media (max-width: 767px) {
  .top-kv__object {
    position: absolute;
    width: 175%;
    height: 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 60rem);
  }
  .top-kv__object.is-active {
    transform: translate(-50%, 0);
  }
}
@media (min-width: 768px) {
  .top-kv__object {
    width: clamp(0rem,58.0414746544vw,916rem);
    padding-top: 22%;
    margin-right: auto;
    margin-left: auto;
    transform: translateY(60rem);
  }
  .top-kv__object.is-active {
    transform: translateY(0);
  }
}
@media (min-width: 768px) and (max-height: 700px) {
  .top-kv__object {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 60rem);
  }
  .top-kv__object.is-active {
    transform: translate(-50%, 0);
  }
}
.top-kv__object.is-active {
  opacity: 1;
}

.top-kv__text-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 767px) {
  .top-kv__text-area {
    height: calc(100% - 90rem);
  }
}
@media (min-width: 768px) {
  .top-kv__text-area {
    max-height: 670rem;
    flex: 1;
  }
}
@media (min-width: 768px) and (max-height: 700px) {
  .top-kv__text-area {
    padding-bottom: 150rem;
  }
}

.top-kv__heading__logo {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 767px) {
  .top-kv__heading__logo {
    width: clamp(0rem,170rem,221px);
  }
}
@media (min-width: 768px) {
  .top-kv__heading__logo {
    width: clamp(0rem,11.2788018433vw,178rem);
  }
}
.top-kv__heading__logo img {
  width: 100%;
}

.top-kv__heading__text {
  display: block;
  font-size: 35rem;
  font-weight: 500;
  letter-spacing: 0.245em;
}
@media (max-width: 767px) {
  .top-kv__heading__text {
    font-size: clamp(0rem,35rem,45.5px);
    margin-top: clamp(0rem,70rem,91px);
  }
}
@media (min-width: 768px) {
  .top-kv__heading__text {
    font-size: clamp(0rem,4.2453917051vw,67rem);
    margin-top: clamp(0rem,5.8294930876vw,92rem);
  }
}

.top-intro {
  text-align: center;
  overflow: hidden;
  margin-top: 100rem;
  margin-bottom: 95rem;
}
@media (min-width: 768px) {
  .top-intro {
    margin-top: clamp(0rem,11.4055299539vw,180rem);
    margin-bottom: clamp(0rem,6.9700460829vw,110rem);
  }
}

.top-intro__lead {
  margin-top: 15rem;
}
@media (min-width: 768px) {
  .top-intro__lead {
    margin-top: clamp(0rem,2.8513824885vw,45rem);
  }
}

.top-intro__button {
  margin-top: 60rem;
}
@media (min-width: 768px) {
  .top-intro__button {
    margin-top: clamp(0rem,8.8709677419vw,140rem);
  }
}
.top-intro__button .m-primary-button {
  margin-right: auto;
  margin-left: auto;
}

.top-feature {
  overflow: hidden;
  padding-bottom: 155rem;
}
@media (min-width: 768px) {
  .top-feature {
    padding: 0 var(--sidePaddingPc1) clamp(0rem,11.4055299539vw,180rem);
  }
}

@media (max-width: 767px) {
  .top-feature__block:not(:last-child) {
    margin-bottom: 75rem;
  }
}

@media (min-width: 768px) {
  .top-feature__block__inner {
    display: flex;
    max-width: 1386rem;
    margin-right: auto;
    margin-left: auto;
  }
  .top-feature__block--reverse .top-feature__block__inner {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.top-feature__textarea {
  position: relative;
}
@media (max-width: 767px) {
  .top-feature__textarea {
    padding: 0 var(--sidePaddingSp1) 0;
  }
}
@media (min-width: 768px) {
  .top-feature__textarea {
    max-width: 573rem;
    padding: clamp(0rem,4.435483871vw,70rem) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .top-feature__textarea__inner {
    top: clamp(0rem,5.702764977vw,90rem);
  }
}

.top-feature__heading {
  overflow: hidden;
}

.top-feature__heading__text {
  font-size: 30rem;
  font-weight: 600;
  letter-spacing: 0.2em;
  margin-bottom: 38rem;
}
@media (min-width: 768px) {
  .top-feature__heading__text {
    font-size: clamp(0rem,2.8513824885vw,45rem);
    margin-bottom: clamp(0rem,4.435483871vw,70rem);
  }
}

.top-feature__lead {
  text-align: justify;
  line-height: 2;
}
@media (max-width: 767px) {
  .top-feature__lead {
    letter-spacing: 0.1em;
  }
}
@media (min-width: 768px) {
  .top-feature__lead {
    font-size: clamp(0rem,1.3940092166vw,22rem);
  }
}
.top-feature__heading + .top-feature__lead {
  margin-top: 0;
}
.top-feature__lead + .top-feature__lead {
  margin-top: 20rem;
}
@media (min-width: 768px) {
  .top-feature__lead + .top-feature__lead {
    margin-top: clamp(0rem,2.2177419355vw,35rem);
  }
}

.top-feature__notes {
  font-size: 10rem;
  letter-spacing: 0.2em;
}
@media (min-width: 768px) {
  .top-feature__notes {
    font-size: 12rem;
  }
}

.top-feature__image {
  display: block;
}
@media (max-width: 767px) {
  .top-feature__image {
    margin-right: -5rem;
    margin-left: auto;
    margin-bottom: 45rem;
    width: 295rem;
  }
}
@media (min-width: 768px) {
  .top-feature__image {
    flex: none;
    width: 42.6%;
    margin-right: 10%;
  }
}
@media (max-width: 767px) {
  .top-feature__block--reverse .top-feature__image {
    width: 329rem;
    margin-left: -5rem;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .top-feature__block--reverse .top-feature__image {
    margin-right: 0;
    width: 47.5%;
    margin-left: 10%;
  }
}

.top-feature__image__inner {
  position: relative;
  width: 100%;
  padding-top: 217%;
}

.top-feature__copyright {
  position: absolute;
  width: 100%;
  height: calc(100% + 30rem);
  top: 0;
  left: 0;
}

.top-feature__copyright__text {
  font-family: var(--fontEn1);
  letter-spacing: 0;
  fill: var(--colorText3);
  font-size: 1.5em;
}
@media (min-width: 768px) {
  .top-feature__copyright__text {
    font-size: 0.7em;
  }
}

.top-feature__image__frame {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.top-feature__block--1 .top-feature__image__frame {
  clip-path: url(#top-feature__shape1);
}
.top-feature__block--2 .top-feature__image__frame {
  clip-path: url(#top-feature__shape2);
}
.top-feature__block--3 .top-feature__image__frame {
  clip-path: url(#top-feature__shape3);
}

.top-feature__image__frame__content {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: var(--vh);
  background: var(--colorMain1);
}
.top-feature__image__frame__content img {
  object-fit: cover;
  object-position: center bottom;
  width: 100%;
  height: 100%;
  opacity: 0;
}
@media (min-width: 768px) {
  .top-feature__block--3 .top-feature__image__frame__content {
    left: auto;
    right: 7%;
  }
}
.top-feature__block--3 .top-feature__image__frame__content img {
  object-position: right bottom;
}

.top-feature__shape__path {
  transform: scale(0.00169, 0.00078);
}

.top-concept {
  text-align: center;
}

.top-concept__text-area {
  overflow: hidden;
}

.top-concept__heading {
  font-size: 30rem;
  font-weight: 600;
  letter-spacing: 0.245em;
  margin-bottom: 30rem;
}
@media (min-width: 768px) {
  .top-concept__heading {
    font-size: clamp(0rem,3.4850230415vw,55rem);
    margin-bottom: 60rem;
  }
}

.top-concept__lead {
  line-height: 2;
}
@media (max-width: 767px) {
  .top-concept__lead {
    letter-spacing: 0.1em;
  }
}
@media (min-width: 768px) {
  .top-concept__lead {
    font-size: clamp(0rem,1.5207373272vw,24rem);
  }
}
.top-concept__lead + .top-concept__lead {
  margin-top: 20rem;
}
@media (min-width: 768px) {
  .top-concept__lead + .top-concept__lead {
    margin-top: clamp(0rem,2.2177419355vw,35rem);
  }
}

.top-concept__logo {
  margin: 110rem auto 75rem;
  width: 140rem;
  position: relative;
}
@media (min-width: 768px) {
  .top-concept__logo {
    width: clamp(0rem,13.0529953917vw,206rem);
    margin-top: clamp(0rem,11.4055299539vw,180rem);
    margin-bottom: clamp(0rem,8.8709677419vw,140rem);
  }
}

.top-contact {
  margin-top: 120rem;
}
@media (min-width: 768px) {
  .top-contact {
    margin-top: clamp(0rem,13.2430875576vw,209rem);
  }
}

.top-contact__button {
  margin-right: auto;
  margin-left: auto;
}

.top-movie-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.top-movie-modal__content {
  margin-right: auto;
  margin-left: auto;
  width: calc(100% - var(--sidePaddingSp2) * 2);
  position: relative;
}
@media (min-width: 768px) {
  .top-movie-modal__content {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 767px) {
  .top-movie-modal__close {
    position: absolute;
    right: 0;
    top: -55rem;
  }
}
@media (min-width: 768px) {
  .top-movie-modal__close {
    margin: 30rem auto 0;
  }
}

.contact-form {
  padding-top: 54rem;
}
@media (min-width: 768px) {
  .contact-form {
    padding-top: clamp(0rem,6.7799539171vw,107rem);
  }
}

.contact-form__inner {
  padding: 0 var(--sidePaddingSp2);
}
@media (min-width: 768px) {
  .contact-form__inner {
    padding: 0 var(--sidePaddingPc1);
  }
}

.contact-form__logo {
  width: 85rem;
  margin: 0 auto 30rem;
}
@media (min-width: 768px) {
  .contact-form__logo {
    width: clamp(0rem,10.7718894009vw,170rem);
    margin: 0 auto clamp(0rem,4.9423963134vw,78rem);
  }
}

@media (max-width: 767px) {
  .contact-form__heading {
    letter-spacing: 0;
  }
}

.contact-form__lead {
  margin-top: 17rem;
  text-align: center;
}
.contact-form__lead--send {
  display: none;
}

.contact-form__content {
  margin: 30rem auto 0;
}
@media (min-width: 768px) {
  .contact-form__content {
    max-width: 840rem;
    margin-top: clamp(0rem,3.801843318vw,60rem);
  }
}

.contact-form__action-button {
  display: none;
  margin-top: 40rem;
}
@media (min-width: 768px) {
  .contact-form__action-button {
    margin-top: clamp(0rem,4.7523041475vw,75rem);
  }
}
.contact-form__action-button .m-primary-button {
  margin-right: auto;
  margin-left: auto;
}

.contact-form.is-form-complete .contact-form__lead--default {
  display: none;
}
.contact-form.is-form-complete .contact-form__lead--send {
  display: block;
}
.contact-form.is-form-complete .contact-form__content {
  display: none;
}
.contact-form.is-form-complete .contact-form__action-button {
  display: block;
}