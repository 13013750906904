.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  transition:.4s;
  &:not(.is-active) {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}
.modal__overlay {
  background:rgba( #000,0.85);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.modal__content {
  position: relative;
  z-index: 1;
  pointer-events: none;
}
.modal__content__inner {
  pointer-events: auto;
}
.modal__movie {
  width: 100%;
  padding-top: 56.4%;
  position: relative;
}
.modal__movie__frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal__close {
  position: relative;
  width: 45rem;
  height: 45rem;
  cursor: pointer;  
  pointer-events: auto;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;  
  @include wide {
    width: 42px;
    height: 42px;
  }
  &::before ,
  &::after {
    content: "";
    position: absolute;
    width:56rem;
    height: 1px;
    top: 50%;
    left: 50%;
    background: #fff;
    @include wide {
      width: 48px;
    }
  }
  &::before {
    transform: translate(-50%,-50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%,-50%) rotate(-45deg);
  }
}