@use "sass:math"; 

// メディアクエリ内はカスタムプロパティ使用不可のためscss変数を使用
$breakSpMin: 374px;
$breakSp: 767px;
$breakPc: 768px;
$designSp: 750;
$designPc: 1736;

// メディアクエリ
// -----------------------------------

@mixin sp {
	@media (max-width: $breakSp) {
		@content;
	}
}
@mixin spmin {
	@media (max-width: $breakSpMin) {
		@content;
	}
}
@mixin wide {
	@media (min-width: $breakPc) {
		@content;
	}
}

// vw計算
// -----------------------------------
@function sp-vw($target) {
	@return calc(($target / $designSp) * 100vw);
}
@function pc-vw($target) {
	@return calc(($target / $designPc) * 100vw);
}


// リキッドレイアウト対応
// -----------------------------------
// ※ designNumにデザイン上の数値を代入して使用する
@function pcLiquidSize($designNum) {
  $vw: $designNum * 1.1;
  $rem: $designNum + rem;
	@return clamp(0rem,#{pc-vw($vw)},#{$rem});
}
@function spLiquidSize($designNum) {
  $rem: $designNum + rem;
  $px: ($designNum * 1.3) + px;
	@return clamp(0rem,#{$rem},#{$px});
}