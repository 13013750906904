.contact-form {
  padding-top: 54rem;
  @include wide {
    padding-top: pcLiquidSize(107);
  }
}
.contact-form__inner {
  padding:0 var(--sidePaddingSp2);
  @include wide {
    padding:0 var(--sidePaddingPc1);
  }
}
.contact-form__logo {
  width: 85rem;
  margin:0 auto 30rem;
  @include wide {
    width: pcLiquidSize(170);
    margin:0 auto pcLiquidSize(78);
  }
}
.contact-form__heading {
  @include sp {
    letter-spacing: 0;
  }
}
.contact-form__lead {
  margin-top: 17rem;
  text-align: center;
  &--send {
    display: none;
  }
}
.contact-form__content {
  margin: 30rem auto 0;
  @include wide {
    max-width: 840rem;
    margin-top: pcLiquidSize(60);
  }
}
.contact-form__action-button {
  display: none;
  margin-top: 40rem;
  @include wide {
    margin-top: pcLiquidSize(75);
  }
  .m-primary-button {
    margin-right: auto;
    margin-left: auto;
  }
}

// 送信完了画面 - is-form-completeの有無で出し分ける

.contact-form.is-form-complete {
  .contact-form__lead {
    &--default {
      display: none;
    }
    &--send {
      display: block;
    }
  }
  .contact-form__content {
    display: none;
  }
  .contact-form__action-button {
    display: block;
  }
}